export const GET_ERRORS = "GET_ERRORS";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const ADD_GROUPS = "ADD_GROUPS";
export const ADD_MEMBERS = "ADD_MEMBERS";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const SEND_BROADCAST_MESSAGE = "SEND_BROADCAST_MESSAGE";
export const SCHEDULE_BROADCAST_MESSAGE = "SCHEDULE_BROADCAST_MESSAGE";
export const GET_VALIDATION = "GET_VALIDATION";
export const REPLY_TO_QUERY = "REPLY_TO_QUERY";
export const GET_QUERIES = "GET_QUERIES";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const DELETE_SCHEDULE = "DELETE_SCHEDULE";

/*SUCCESS FACTOR*/
export const GET_ALL_BUSINESS_GROUPS = "GET_ALL_BUSINESS_GROUPS";
export const GET_ALL_BUSINESS_UNITS = "GET_ALL_BUSINESS_UNITS";
export const GET_ALL_LOCATIONS = "GET_ALL_LOCATIONS";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const CREATE_EVENT = "CREATE_EVENT";
export const GET_ALL_EVENTS = "GET_ALL_EVENTS";
export const GET_ALL_SCHEDULES = "GET_ALL_SCHEDULES";
export const DELETE_OPTION = "DELETE_OPTION";
