import React, { Component } from "react";
import { Row, Col, Button, Space, Table, Select, Tooltip } from "antd";
import { connect } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { saveAs } from "file-saver";
import axios from "axios";
import BreadCrumbs from "../../BreadCrumbs";
import { CSVLink } from "react-csv";
import { getAllQueriesByBroadcastId } from "../../../actions/eventActions";
import { Auth } from "aws-amplify";

class ViewBroadcastMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: null,
      tableData: null,
    };
  }
  //On Tab Change
  onChange = (key) => {};

  //On Change Message Status
  messageStatus = (value) => {
    if (value === "sent") {
      var status = this.props.location.state.allrecipients.filter(
        (data) => data.status === value
      );
      this.setState({
        initialData: status,
      });
    } else if (value === "failed") {
      var status = this.props.location.state.allrecipients.filter(
        (data) => data.status !== "sent"
      );
      this.setState({
        initialData: status,
      });
    } else {
      this.setState({
        initialData: this.state.tableData,
      });
    }
  };
  async componentDidMount() {
    this.setState({
      initialData: this.props.location.state.allrecipients,
    });
    this.setState({
      tableData: this.props.location.state.allrecipients,
    });
  }

  //Downloading Image
  downloadImage = async (key) => {
    let accessToken = (await Auth.currentSession())
      .getAccessToken()
      .getJwtToken();
    let response = await axios.get(
      `https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/presignedurl?key=${key}`,
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    saveAs(response.data.url);
  };

  render() {
    const headers = [
      { label: "Message", key: "message" },
      { label: "Media", key: "media" },
      { label: "Number", key: "number" },
      { label: "Recipient Name", key: "recipientName" },
      { label: "status", key: "status" },
    ];
    const data = this.props.location.state?.allrecipients.map((element) => {
      element.message = this.props.location.state.message;
      element.media = this.props.location.state.media;
      element.number = element.number + " ";
      return element;
    });

    const items = [
      {
        key: "1",
        value: "Status",
        label: "Status",
      },
      {
        key: "2",
        value: "sent",
        label: "Sent",
      },
      {
        key: "3",
        value: "failed",
        label: "Failed",
      },
    ];

    const messageStatisticsCols = [
      {
        title: "Number",
        dataIndex: "number",
        align: "left",
        width: "200px",
      },
      {
        title: "Name",
        dataIndex: "recipientName",
        align: "center",
      },
      {
        title: (
          <Space size="middle">
            <Select
              onChange={this.messageStatus}
              style={{ width: "110px" }}
              defaultValue="Status"
              options={items}
            ></Select>
          </Space>
        ),

        dataIndex: "status",
        width: "200px",
        align: "center",

        render: (text) =>
          text === "sent" ? (
            <p className="text-success my-para">Sent</p>
          ) : (
            <p className="text-danger">
              Number is invalid or not registered with whatsapp
            </p>
          ),
      },
    ];

    return (
      <div className="container dashboard statistics-card">
        <Row gutter={[24, 24]}>
          <Col span={12}>
            {" "}
            <div className="page-title">Message</div>
            <BreadCrumbs location={this.props.location} />
          </Col>
          <Col span={12}>
            <CSVLink
              data={data}
              headers={headers}
              className="float-end btn-dwn"
              filename={"message-statistics-report.csv"}
            >
              <Tooltip title="Export to csv">
                <div className="download-btn float-end">
                  <Button size="large" type="text">
                    <DownloadOutlined />
                  </Button>
                </div>
              </Tooltip>
            </CSVLink>
          </Col>
        </Row>

        <div className="mt-4">
          <div className="card">
            <div className="card-body p-5">
              <Tabs
                defaultActiveKey="1"
                onChange={this.onChange}
                items={[
                  {
                    label: `Message Statistics`,
                    key: "2",
                    children: (
                      <Table
                        columns={messageStatisticsCols}
                        dataSource={this.state.initialData}
                        rowKey="_id"
                        align="left"
                      />
                    ),
                  },
                  {
                    label: `Attachments`,
                    key: "3",
                    children:
                      this.props.location.state.media !== "" ? (
                        <Row className="title-row">
                          <Col>{this.props.location.state.fileName}</Col>
                          <Col xs={4} sm={4} md={4} lg={1} xl={1}></Col>
                          <Col>
                            <button
                              type="button"
                              className="rounded btn-success"
                              onClick={(event) =>
                                this.downloadImage(
                                  this.props.location.state.media
                                )
                              }
                            >
                              <DownloadOutlined />
                            </button>
                          </Col>
                        </Row>
                      ) : (
                        <div>No Attachments</div>
                      ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  groups: state.users.groups,
  sendBroadcastMsg: state.users.sendBroadCastMsgResponse,
  queries: state.events.queries,
});
export default connect(mapStateToProps, {
  getAllQueriesByBroadcastId,
})(ViewBroadcastMessage);
