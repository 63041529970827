import { DownloadOutlined } from "@ant-design/icons";
import { CRow } from "@coreui/react";
import { Button, Card, Col, Row, Select, Spin, Table, Tooltip } from "antd";
import { Auth } from "aws-amplify";
import { groupBy } from "lodash";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import BreadCrumbs from "../../BreadCrumbs";

const FeedbackReport = (props) => {
  const [data, setData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [groupedData, setGroupedData] = useState({});
  const [filteredGroupedData, setFilteredGroupedData] = useState({});
  const [loading, setLoading] = useState(false);
  let questionSet = new Set();

  useEffect(() => {
    const fetchData = async () => {
      let accessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      setLoading(true);
      fetch(
        "https://ivdnmmmgn3.execute-api.ap-south-1.amazonaws.com/Prod/feedbackreportbyuser",
        { headers: { Authorization: "Bearer " + accessToken } }
      )
        .then((response) => response.json())
        .then((data) => {
          let newData = JSON.parse(data.body);
          console.log("new data", newData);
          setData(newData.data);
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const groupData = () => {
      const grouped = groupBy(data, "eventname");
      setGroupedData(grouped);
      setFilteredGroupedData(grouped);
    };
    groupData();
  }, [data]);

  const handleEventChange = (value) => {
    setSelectedEvent(value);
    if (value) {
      const filtered = {};
      filtered[value] = groupedData[value];
      setFilteredGroupedData(filtered);
    } else {
      setFilteredGroupedData(groupedData);
    }
    questionArrData({ selectedEvent, groupedData });
  };

  const questionArrData = ({ selectedEvent, groupedData }) => {
    return (
      <React.Fragment>
        {selectedEvent &&
          groupedData[selectedEvent].flatMap((data) =>
            data.feedback.map((indata, index) => (
              <React.Fragment key={index}>
                {questionSet.add(indata.questions)}
                <div>{indata.Question}</div>
              </React.Fragment>
            ))
          )}
      </React.Fragment>
    );
  };

  const columns = [
    {
      title: "S.No",
      key: "sno",
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Mobile Number", dataIndex: "mobileNumber", key: "mobileNumber" },
    {
      title: "Q1",
      dataIndex: "",
      key: "",
      render: (text, record) =>
        record.feedback[0] ? record.feedback[0].answer : "-",
    },
    {
      title: "Q2",
      dataIndex: "",
      key: "",
      render: (text, record) =>
        record.feedback[1] ? record.feedback[1].answer : "-",
    },
    {
      title: "Q3",
      dataIndex: "",
      key: "",
      render: (text, record) =>
        record.feedback[2] ? record.feedback[2].answer : "-",
    },
    {
      title: "Q4",
      dataIndex: "",
      key: "",
      render: (text, record) =>
        record.feedback[3] ? record.feedback[3].answer : "-",
    },
    {
      title: "Q5",
      dataIndex: "",
      key: "",
      render: (text, record) =>
        record.feedback[4] ? record.feedback[4].answer : "-",
    },
  ];

  let questionCSVDATA = [];
  let csvData = [];
  if (selectedEvent) {
    questionCSVDATA = Array.from(
      new Set(
        groupedData[selectedEvent].flatMap((event) =>
          event.feedback.map((item) => item.Question)
        )
      )
    );

    csvData = groupedData[selectedEvent].map((event, index) => {
      const { eventname, mobileNumber, name, feedback } = event;
      const feedbackData = feedback.reduce((acc, question) => {
        acc[question.Question] = question.answer;
        return acc;
      }, {});
      return { sno: index + 1, eventname, mobileNumber, name, ...feedbackData };
    });
  }

  const headers = [
    { label: "S. No.", key: "sno" },
    { label: "Event Name", key: "eventname" },
    { label: "Mobile Number", key: "mobileNumber" },
    { label: "Name", key: "name" },
    ...questionCSVDATA.map((question) => ({ label: question, key: question })),
  ];

  return (
    <div className="container dashboard statistics-card">
      <div>
        <Row>
          <Col span={6}>
            <div className="row ">
              <div className="col-8">
                <h2 className="page-title">Feedback Report</h2>
                <BreadCrumbs location={props.location} />
              </div>
            </div>
            <Select
              // defaultValue=""
              value={selectedEvent}
              style={{ width: 200, marginTop: "38px" }}
              onChange={handleEventChange}
            >
              <Select.Option value="" selected>
                All Events
              </Select.Option>
              {Object.keys(groupedData).map((eventName) => (
                <Select.Option key={eventName} value={eventName}>
                  {eventName}
                </Select.Option>
              ))}
            </Select>
            {selectedEvent && (
              <CRow>
                <CSVLink
                  data={csvData}
                  headers={headers}
                  className="float-end btn-dwn"
                  filename={"cumulative-feedback-report.csv"}
                >
                  <Tooltip title="Export Report to csv">
                    <div
                      className="dwnld-btn"
                      style={{
                        marginLeft: "205px",
                      }}
                    >
                      <Button size="large" type="text">
                        <DownloadOutlined />
                      </Button>
                    </div>
                  </Tooltip>
                </CSVLink>
              </CRow>
            )}
          </Col>
          <Col span={18}>
            {selectedEvent && (
              <Card
                style={{
                  width: "302px",
                  borderRadius: "5px",
                  border: "none",
                  left: "40rem",
                  background: "#e3e1ff",
                }}
              >
                {groupedData[selectedEvent][0].feedback.map((indata, index) => {
                  const question = indata.Question;
                  return (
                    <Row gutter={[16, 16]}>
                      <div key={index}>
                        <b>{"Question " + (index + 1) + ": "}</b> {question}
                        <br />
                      </div>
                    </Row>
                  );
                })}
              </Card>
            )}
          </Col>
        </Row>
      </div>

      <div className="mt-4">
        <div className="card">
          <div className="card-body p-5">
            <div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Spin />
                </div>
              ) : (
                Object.keys(filteredGroupedData).map((eventName) => (
                  <div key={eventName}>
                    <h4>
                      <b>{eventName}</b>
                    </h4>
                    <br />

                    <Table
                      columns={columns}
                      dataSource={filteredGroupedData[eventName]}
                      rowKey={(record) => record._id}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeedbackReport;
